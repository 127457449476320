var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "panel-tab__content" },
    [
      _c("a-table", {
        attrs: {
          size: "small",
          columns: _vm.elementPropertyListColumns,
          "data-source": _vm.elementPropertyList,
          "max-height": "240",
          pagination: false,
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (text, record, index) {
              return _c("span", {}, [_vm._v(" " + _vm._s(index + 1) + " ")])
            },
          },
          {
            key: "action",
            fn: function (text, record, index) {
              return _c(
                "span",
                {},
                [
                  _c("a-divider", { attrs: { type: "vertical" } }),
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.openAttributesForm(record, index)
                        },
                      },
                    },
                    [_vm._v("编辑")]
                  ),
                  _c("a-divider", { attrs: { type: "vertical" } }),
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.removeAttributes(record, index)
                        },
                      },
                    },
                    [_vm._v(" 移除 ")]
                  ),
                ],
                1
              )
            },
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "element-drawer__button" },
        [
          _c(
            "a-button",
            {
              attrs: { size: "small", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.openAttributesForm(null, -1)
                },
              },
            },
            [_vm._v("添加属性")]
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "属性配置",
            visible: _vm.propertyFormModelVisible,
            width: "600px",
          },
          on: {
            ok: _vm.saveAttribute,
            cancel: function ($event) {
              _vm.propertyFormModelVisible = false
            },
          },
        },
        [
          _c(
            "a-form",
            {
              ref: "attributeFormRef",
              attrs: {
                form: _vm.propertyForm,
                "label-col": { span: 5 },
                "wrapper-col": { span: 19 },
              },
            },
            [
              _c(
                "a-form-item",
                { attrs: { label: "属性名：", prop: "name" } },
                [
                  _c("a-input", {
                    attrs: { allowClear: "" },
                    model: {
                      value: _vm.propertyForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.propertyForm, "name", $$v)
                      },
                      expression: "propertyForm.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "属性值：", prop: "value" } },
                [
                  _c("a-input", {
                    attrs: { allowClear: "" },
                    model: {
                      value: _vm.propertyForm.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.propertyForm, "value", $$v)
                      },
                      expression: "propertyForm.value",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }