import request from '@/utils/request'

// 获取流程定义集合
export function list(query) {
  return request({
    url: '/processDefinition/list',
    method: 'get',
    params: query
  })
}
// 通过stringBPMN添加流程定义
export function addDeploymentByString(data) {
  return request({
    url: '/processDefinition/addDeploymentByString',
    method: 'post',
    data: data
  })
}

// 通过实例ID获取流程定义
export function getDefinitions(id) {
  return request({
    url: '/processDefinition/getDefinitions/' + id,
    method: 'get'
  })
}

// 删除流程定义
export function remove(deploymentId) {
  return request({
    url: '/processDefinition/remove/' + deploymentId,
    method: 'delete'
  })
}
// 获取流程定义XML
export function getDefinitionXML(query) {
  return request({
    url: '/processDefinition/getDefinitionXML',
    method: 'get',
    params: query
  })
}
