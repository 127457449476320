var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "process-panel__container",
      style: { width: this.width + "px" },
    },
    [
      _c(
        "a-collapse",
        {
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        [
          _c(
            "a-collapse-panel",
            { key: "base", attrs: { header: "常规" } },
            [
              _c("element-base-info", {
                attrs: {
                  "id-edit-disabled": _vm.idEditDisabled,
                  "business-object": _vm.elementBusinessObject,
                  type: _vm.elementType,
                },
              }),
            ],
            1
          ),
          _vm.elementType === "Process"
            ? _c(
                "a-collapse-panel",
                { key: "condition", attrs: { header: "消息与信号" } },
                [_c("signal-and-massage")],
                1
              )
            : _vm._e(),
          _vm.conditionFormVisible
            ? _c(
                "a-collapse-panel",
                { key: "condition", attrs: { header: "流转条件" } },
                [
                  _c("flow-condition", {
                    attrs: {
                      "business-object": _vm.elementBusinessObject,
                      type: _vm.elementType,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.elementType.indexOf("Task") !== -1
            ? _c(
                "a-collapse-panel",
                { key: "task", attrs: { header: "任务" } },
                [
                  _c("element-task", {
                    attrs: { id: _vm.elementId, type: _vm.elementType },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-collapse-panel",
            { key: "listeners", attrs: { header: "执行监听器" } },
            [
              _c("element-listeners", {
                attrs: { id: _vm.elementId, type: _vm.elementType },
              }),
            ],
            1
          ),
          _vm.elementType === "UserTask"
            ? _c(
                "a-collapse-panel",
                { key: "taskListeners", attrs: { header: "任务监听器" } },
                [
                  _c("user-task-listeners", {
                    attrs: { id: _vm.elementId, type: _vm.elementType },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }