var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "margin-top": "16px" } },
    [
      _c("a-form-item", { attrs: { label: "消息实例" } }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "justify-content": "space-between",
              "flex-wrap": "nowrap",
            },
          },
          [
            _c(
              "a-select",
              {
                on: { change: _vm.updateTaskMessage },
                model: {
                  value: _vm.bindMessageId,
                  callback: function ($$v) {
                    _vm.bindMessageId = $$v
                  },
                  expression: "bindMessageId",
                },
              },
              _vm._l(Object.keys(_vm.messageMap), function (item) {
                return _c(
                  "a-select-option",
                  { key: item, attrs: { value: item } },
                  [_vm._v(" " + _vm._s(_vm.messageMap[item]) + " ")]
                )
              }),
              1
            ),
            _c("a-button", {
              staticStyle: { "margin-left": "8px" },
              attrs: { size: "small", type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.openMessageModel },
            }),
          ],
          1
        ),
      ]),
      _c(
        "a-modal",
        {
          attrs: {
            title: "创建新消息",
            visible: _vm.messageModelVisible,
            width: "400px",
          },
          on: {
            ok: _vm.createNewMessage,
            cancel: function ($event) {
              _vm.messageModelVisible = false
            },
          },
        },
        [
          _c(
            "a-form",
            {
              attrs: {
                form: _vm.newMessageForm,
                "label-col": { span: 5 },
                "wrapper-col": { span: 19 },
              },
            },
            [
              _c(
                "a-form-item",
                { attrs: { label: "消息ID" } },
                [
                  _c("a-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.newMessageForm.id,
                      callback: function ($$v) {
                        _vm.$set(_vm.newMessageForm, "id", $$v)
                      },
                      expression: "newMessageForm.id",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "消息名称" } },
                [
                  _c("a-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.newMessageForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.newMessageForm, "name", $$v)
                      },
                      expression: "newMessageForm.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }