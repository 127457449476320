/*
 * @Auth: linjituan
 * @Date: 2021-09-29 19:13:03
 * @LastEditors: linjituan
 * @LastEditTime: 2021-11-01 19:37:44
 */
import MyProcessDesigner from './process-designer'
import MyProcessPenal from './refactor'
// 导入bpmn相关样式
import './theme/element-variables.less'
import './theme/index.less'
import 'bpmn-js/dist/assets/diagram-js.css'
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn.css'
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css'

const components = [MyProcessDesigner, MyProcessPenal]

const install = function(Vue) {
  components.forEach(component => {
    Vue.component(component.name, component)
  })
}

if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}

export default {
  version: '0.0.1',
  install,
  ...components
}
