var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "panel-tab__content" },
    [
      _c(
        "a-form",
        {
          attrs: {
            form: _vm.taskConfigForm,
            "label-col": { span: 5 },
            "wrapper-col": { span: 19 },
          },
        },
        [
          _c(
            "a-form-item",
            { attrs: { label: "异步延续" } },
            [
              _c(
                "a-checkbox",
                {
                  on: { change: _vm.changeTaskAsync },
                  model: {
                    value: _vm.taskConfigForm.asyncBefore,
                    callback: function ($$v) {
                      _vm.$set(_vm.taskConfigForm, "asyncBefore", $$v)
                    },
                    expression: "taskConfigForm.asyncBefore",
                  },
                },
                [_vm._v(" 异步前 ")]
              ),
              _c(
                "a-checkbox",
                {
                  on: { change: _vm.changeTaskAsync },
                  model: {
                    value: _vm.taskConfigForm.asyncAfter,
                    callback: function ($$v) {
                      _vm.$set(_vm.taskConfigForm, "asyncAfter", $$v)
                    },
                    expression: "taskConfigForm.asyncAfter",
                  },
                },
                [_vm._v(" 异步后 ")]
              ),
              _vm.taskConfigForm.asyncAfter || _vm.taskConfigForm.asyncBefore
                ? _c(
                    "a-checkbox",
                    {
                      on: { change: _vm.changeTaskAsync },
                      model: {
                        value: _vm.taskConfigForm.exclusive,
                        callback: function ($$v) {
                          _vm.$set(_vm.taskConfigForm, "exclusive", $$v)
                        },
                        expression: "taskConfigForm.exclusive",
                      },
                    },
                    [_vm._v(" 排除 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            _vm.witchTaskComponent,
            _vm._b({ tag: "component" }, "component", _vm.$props, false)
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }