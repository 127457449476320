<template>
  <div class="my-process-designer">
    <div class="my-process-designer__header">
      <slot name="control-header"></slot>
      <template v-if="!$slots['control-header']">
        <a-button-group>
          <a-button class="button-file" :size="headerButtonSize" :type="headerButtonType" @click="$refs.refFile.click()">打开文件</a-button>
          <!-- <a-dropdown class="button-file">
            <a-menu slot="overlay" @click="handleMenuClick">
              <a-menu-item key="1">
                下载为XML文件
              </a-menu-item>
              <a-menu-item key="2">
                下载为SVG文件
              </a-menu-item>
              <a-menu-item key="3">
                下载为BPMN文件
              </a-menu-item>
            </a-menu>
            <a-button> 下载文件 <a-icon type="down" /> </a-button>
          </a-dropdown> -->
          <a-button @click="handleMenuClick"> 下载文件 </a-button>
          <!-- <a-upload name="file" :action="uploadUrl" :headers="headers" @change="handleChange">
            <a-button> <a-icon type="upload" /> 上传部署 </a-button>
          </a-upload> -->
          <a-button class="button-file" :size="headerButtonSize" :type="headerButtonType" @click="beforeDoSave">保存</a-button>
        </a-button-group>
        <a-button-group>
          <a-tooltip effect="light" content="向左对齐">
            <template slot="title"> 向左对齐 </template>
            <img class="button-align" :src="alignLeft" alt="" @click="elementsAlign('left')" />
          </a-tooltip>
          <a-tooltip effect="light" content="向右对齐">
            <template slot="title"> 向右对齐 </template>
            <img class="button-align" :src="alignRight" alt="" @click="elementsAlign('right')" />
          </a-tooltip>
          <a-tooltip effect="light" content="向上对齐">
            <template slot="title"> 向上对齐 </template>
            <img class="button-align" :src="alignTop" alt="" @click="elementsAlign('top')" />
          </a-tooltip>
          <a-tooltip effect="light" content="向下对齐">
            <template slot="title"> 向下对齐 </template>
            <img class="button-align" :src="alignBottom" alt="" @click="elementsAlign('bottom')" />
          </a-tooltip>
          <a-tooltip effect="light" content="水平居中">
            <template slot="title"> 水平居中 </template>
            <img class="button-align" :src="alignCenter" alt="" @click="elementsAlign('center')" />
          </a-tooltip>
          <a-tooltip effect="light" content="垂直居中">
            <template slot="title"> 垂直居中 </template>
            <img class="button-align" :src="alignMiddle" alt="" @click="elementsAlign('middle')" />
          </a-tooltip>
        </a-button-group>
        <a-button-group>
          <a-tooltip effect="light" content="缩小视图">
            <template slot="title"> 缩小视图 </template>
            <img class="button-align" :src="zoomOut" alt="" @click="processZoomOut()" :disabled="defaultZoom < 0.2" />
          </a-tooltip>
          <a-button :size="headerButtonSize">{{ Math.floor(this.defaultZoom * 10 * 10) + '%' }}</a-button>
          <a-tooltip effect="light" content="放大视图">
            <template slot="title"> 放大视图 </template>
            <img class="button-align" :src="zoomIn" alt="" @click="processZoomIn()" :disabled="defaultZoom > 4" />
          </a-tooltip>
          <a-tooltip effect="light" content="重置视图并居中">
            <template slot="title"> 重置视图并居中 </template>
            <img class="button-align" :src="alignOriginal" alt="" @click="processReZoom()" />
          </a-tooltip>
        </a-button-group>
        <a-button-group>
          <a-tooltip effect="light" content="撤销">
            <template slot="title"> 撤销 </template>
            <img class="button-align" :src="undoImg" alt="" @click="processUndo()" :disabled="!revocable" />
          </a-tooltip>
          <a-tooltip effect="light" content="恢复">
            <template slot="title"> 恢复 </template>
            <img class="button-align" :src="redoImg" alt="" @click="processRedo()" :disabled="!revocable" />
          </a-tooltip>
          <a-tooltip effect="light" content="重新绘制">
            <template slot="title"> 重新绘制 </template>
            <img class="button-align" :src="restartImg" alt="" @click="processRestart()" />
          </a-tooltip>
        </a-button-group>
      </template>
      <!-- 用于打开本地文件-->
      <input type="file" id="files" ref="refFile" style="display: none" accept=".bpmn" @change="importLocalFile" />
    </div>
    <div class="my-process-designer__container">
      <div class="my-process-designer__canvas" ref="bpmn-canvas"></div>
    </div>
    <a-modal title="保存" :visible="setDeploymentNameVisible" @ok="handleSetDeploymentNameOk" @cancel="setDeploymentNameVisible = false" :z-index="10000">
      <a-form-model-item label="流程部署名称">
        <a-input v-model="deploymentName" />
      </a-form-model-item>
      <a-form-model-item label="所属机构">
        <a-tree-select
          v-model="deptId"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="deptOptions"
          placeholder="请选择"
          :replaceFields="replaceFields"
          tree-default-expand-all
        >
        </a-tree-select>
      </a-form-model-item>
    </a-modal>
  </div>
</template>

<script>
import { TreeSelect } from 'ant-design-vue'
import { treeselect } from '@/api/system/dept'
// 生产环境时优化
// const BpmnModeler = window.BpmnJS;
import BpmnModeler from 'bpmn-js/lib/Modeler'
import DefaultEmptyXML from './plugins/defaultEmpty'
// 翻译方法
import customTranslate from './plugins/translate/customTranslate'
import translationsCN from './plugins/translate/zh'
// 标签解析构建器
// import bpmnPropertiesProvider from "bpmn-js-properties-panel/lib/provider/bpmn";
// 标签解析 Moddle
import camundaModdleDescriptor from './plugins/descriptor/camundaDescriptor.json'
import activitiModdleDescriptor from './plugins/descriptor/activitiDescriptor.json'
import flowableModdleDescriptor from './plugins/descriptor/flowableDescriptor.json'
// 标签解析 Extension
import camundaModdleExtension from './plugins/extension-moddle/camunda'
import activitiModdleExtension from './plugins/extension-moddle/activiti'
import flowableModdleExtension from './plugins/extension-moddle/flowable'
import { addDeploymentByString } from '@/api/approval/processDefinition'
import storage from 'store'
import { ACCESS_TOKEN } from '@/store/mutation-types'
export default {
  name: 'MyProcessDesigner',
  componentName: 'MyProcessDesigner',
  props: {
    value: { type: String, default: () => {} }, // xml 字符串
    processId: { type: String, default: () => {} },
    processName: { type: String, default: () => {} },
    translations: { type: Object, default: () => {} }, // 自定义的翻译文件
    additionalModel: { type: [Object, Array], default: () => {} }, // 自定义model
    moddleExtension: { type: Object, default: () => {} }, // 自定义moddle
    onlyCustomizeAddi: {
      type: Boolean,
      default: false
    },
    onlyCustomizeModdle: {
      type: Boolean,
      default: false
    },
    simulation: {
      type: Boolean,
      default: true
    },
    keyboard: {
      type: Boolean,
      default: true
    },
    prefix: {
      type: String,
      default: 'camunda'
    },
    events: {
      type: Array,
      default: () => ['element.click']
    },
    headerButtonSize: {
      type: String,
      default: 'small',
      validator: (value) => ['default', 'medium', 'small', 'mini'].indexOf(value) !== -1
    },
    headerButtonType: {
      type: String,
      default: 'primary',
      validator: (value) => ['default', 'primary', 'success', 'warning', 'danger', 'info'].indexOf(value) !== -1
    }
  },
  components: {
    ATreeSelect: TreeSelect
  },
  data() {
    return {
      defaultZoom: 1,
      previewModelVisible: false,
      simulationStatus: false,
      previewResult: '',
      previewType: 'xml',
      recoverable: false,
      revocable: false,
      alignLeft: require('@/assets/images/align-left.png'),
      alignRight: require('@/assets/images/align-right.png'),
      alignTop: require('@/assets/images/align-top.png'),
      alignBottom: require('@/assets/images/align-bottom.png'),
      alignCenter: require('@/assets/images/align-center.png'),
      alignMiddle: require('@/assets/images/align-middle.png'),
      zoomIn: require('@/assets/images/zoom-in.png'),
      zoomOut: require('@/assets/images/zoom-out.png'),
      alignOriginal: require('@/assets/images/align-original.png'),
      undoImg: require('@/assets/images/undo.png'),
      redoImg: require('@/assets/images/redo.png'),
      restartImg: require('@/assets/images/restart.png'),
      uploadUrl: process.env.VUE_APP_BASE_API + '/processDefinition/uploadStreamAndDeployment',
      headers: {
        Authorization: 'Bearer ' + storage.get(ACCESS_TOKEN)
      },
      deploymentName: '',
      setDeploymentNameVisible: false,
      deptId: '',
      replaceFields: { children: 'children', title: 'label', key: 'id', value: 'id' },
      deptOptions: []
    }
  },
  computed: {
    additionalModules() {
      const Modules = []
      // 仅保留用户自定义扩展模块
      if (this.onlyCustomizeAddi) {
        if (Object.prototype.toString.call(this.additionalModel) === '[object Array]') {
          return this.additionalModel || []
        }
        return [this.additionalModel]
      }

      // 插入用户自定义扩展模块
      if (Object.prototype.toString.call(this.additionalModel) === '[object Array]') {
        Modules.push(...this.additionalModel)
      } else {
        this.additionalModel && Modules.push(this.additionalModel)
      }

      // 翻译模块
      const TranslateModule = {
        translate: ['value', customTranslate(this.translations || translationsCN)]
      }
      Modules.push(TranslateModule)

      // 模拟流转模块
      if (this.simulation) {
        // Modules.push(tokenSimulation)
      }

      // 根据需要的流程类型设置扩展元素构建模块
      // if (this.prefix === "bpmn") {
      //   Modules.push(bpmnModdleExtension);
      // }
      if (this.prefix === 'camunda') {
        Modules.push(camundaModdleExtension)
      }
      if (this.prefix === 'flowable') {
        Modules.push(flowableModdleExtension)
      }
      if (this.prefix === 'activiti') {
        Modules.push(activitiModdleExtension)
      }

      return Modules
    },
    moddleExtensions() {
      const Extensions = {}
      // 仅使用用户自定义模块
      if (this.onlyCustomizeModdle) {
        return this.moddleExtension || null
      }

      // 插入用户自定义模块
      if (this.moddleExtension) {
        for (const key in this.moddleExtension) {
          Extensions[key] = this.moddleExtension[key]
        }
      }

      // 根据需要的 "流程类型" 设置 对应的解析文件
      if (this.prefix === 'activiti') {
        Extensions.activiti = activitiModdleDescriptor
      }
      if (this.prefix === 'flowable') {
        Extensions.flowable = flowableModdleDescriptor
      }
      if (this.prefix === 'camunda') {
        Extensions.camunda = camundaModdleDescriptor
      }

      return Extensions
    }
  },
  created() {
    this.getTreeselect()
  },
  mounted() {
    this.initBpmnModeler()
    this.createNewDiagram(this.value)
    this.$once('hook:beforeDestroy', () => {
      if (this.bpmnModeler) this.bpmnModeler.destroy()
      this.$emit('destroy', this.bpmnModeler)
      this.bpmnModeler = null
    })
  },
  methods: {
    /** 查询部门下拉树结构 */
    getTreeselect() {
      const query = {}
      treeselect(query).then((response) => {
        this.deptOptions = response.data
      })
    },
    beforeDoSave() {
      const bpmnElement = window?.bpmnInstances?.bpmnElement
      if (bpmnElement) {
        const elementBaseInfo = JSON.parse(JSON.stringify(bpmnElement.businessObject))
        if (elementBaseInfo) {
          if (!elementBaseInfo.id) {
            this.$warningEx('请输入流程Key')
            return
          }
          if (!elementBaseInfo.name) {
            this.$warningEx('请输入流程名称')
            return
          }
          this.setDeploymentNameVisible = true
        }
      }
    },
    handleChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`)
      }
    },
    handleSetDeploymentNameOk() {
      if (!this.deploymentName) {
        this.$warningEx('请输入流程部署名称')
        return
      }

      if (!this.deptId) {
        this.$warningEx('请选择所属机构')
        return
      }

      this.setDeploymentNameVisible = false
      this.saveBpmn()
    },
    /**
     * 保存bpmn对象
     */
    saveBpmn() {
      const that = this
      this.bpmnModeler.saveXML({ format: true }, function (err, xml) {
        if (err) {
          return console.error('保存失败，请重试', err)
        }
        console.log(xml)
        const formData = new FormData()
        formData.append('stringBPMN', xml)
        formData.append('deploymentName', that.deploymentName)
        formData.append('deptId', that.deptId)
        addDeploymentByString(formData).then((response) => {
          that.$emit('saveOk')
          that.$success({
            title: '提示',
            content: '保存成功'
          })
        })
      })
    },
    handleMenuClick(e) {
      console.log('handleMenuClick', e)
      this.downloadProcessAsBpmn()
      // if (e.key === '1') {
      //   this.downloadProcessAsXml()
      // } else if (e.key === '2') {
      //   this.downloadProcessAsSvg()
      // } else if (e.key === '3') {
      //   this.downloadProcessAsBpmn()
      // }
    },
    initBpmnModeler() {
      if (this.bpmnModeler) return
      this.bpmnModeler = new BpmnModeler({
        container: this.$refs['bpmn-canvas'],
        keyboard: this.keyboard ? { bindTo: document } : null,
        additionalModules: this.additionalModules,
        moddleExtensions: this.moddleExtensions
      })
      this.$emit('init-finished', this.bpmnModeler)
      this.initModelListeners()
    },
    initModelListeners() {
      const EventBus = this.bpmnModeler.get('eventBus')
      const that = this
      // 注册需要的监听事件, 将. 替换为 - , 避免解析异常
      this.events.forEach((event) => {
        EventBus.on(event, function (eventObj) {
          const eventName = event.replace(/\./g, '-')
          const element = eventObj ? eventObj.element : null
          that.$emit(eventName, element, eventObj)
        })
      })
      // 监听图形改变返回xml
      EventBus.on('commandStack.changed', async (event) => {
        try {
          this.recoverable = this.bpmnModeler.get('commandStack').canRedo()
          this.revocable = this.bpmnModeler.get('commandStack').canUndo()
          const { xml } = await this.bpmnModeler.saveXML({ format: true })
          this.$emit('commandStack-changed', event)
          this.$emit('input', xml)
          this.$emit('change', xml)
        } catch (e) {
          console.error(`[Process Designer Warn]: ${e.message || e}`)
        }
      })
      // 监听视图缩放变化
      this.bpmnModeler.on('canvas.viewbox.changed', ({ viewbox }) => {
        this.$emit('canvas-viewbox-changed', { viewbox })
        const { scale } = viewbox
        this.defaultZoom = Math.floor(scale * 100) / 100
      })
    },
    /* 创建新的流程图 */
    async createNewDiagram(xml) {
      // 将字符串转换成图显示出来
      const newId = this.processId
      const newName = this.processName
      const xmlString = xml || DefaultEmptyXML(newId, newName, this.prefix)
      try {
        const { warnings } = await this.bpmnModeler.importXML(xmlString)
        if (warnings && warnings.length) {
          warnings.forEach((warn) => console.warn(warn))
        }
      } catch (e) {
        console.error(`[Process Designer Warn]: ${e.message || e}`)
      }
    },

    // 下载流程图到本地
    async downloadProcess(type, name) {
      try {
        const _this = this
        // 按需要类型创建文件并下载
        if (type === 'xml' || type === 'bpmn') {
          const { err, xml } = await this.bpmnModeler.saveXML()
          // 读取异常时抛出异常
          if (err) {
            console.error(`[Process Designer Warn ]: ${err.message || err}`)
          }
          const { href, filename } = _this.setEncoded(type.toLowerCase(), name, xml)
          downloadFunc(href, filename)
        } else {
          const { err, svg } = await this.bpmnModeler.saveSVG()
          // 读取异常时抛出异常
          if (err) {
            return console.error(err)
          }
          const { href, filename } = _this.setEncoded('SVG', name, svg)
          downloadFunc(href, filename)
        }
      } catch (e) {
        console.error(`[Process Designer Warn ]: ${e.message || e}`)
      }
      // 文件下载方法
      function downloadFunc(href, filename) {
        if (href && filename) {
          const a = document.createElement('a')
          a.download = filename // 指定下载的文件名
          a.href = href //  URL对象
          a.click() // 模拟点击
          URL.revokeObjectURL(a.href) // 释放URL 对象
        }
      }
    },

    // 根据所需类型进行转码并返回下载地址
    setEncoded(type, filename = 'diagram', data) {
      const encodedData = encodeURIComponent(data)
      return {
        filename: `${filename}.${type}`,
        href: `data:application/${type === 'svg' ? 'text/xml' : 'bpmn20-xml'};charset=UTF-8,${encodedData}`,
        data: data
      }
    },

    // 加载本地文件
    importLocalFile() {
      const that = this
      const file = this.$refs.refFile.files[0]
      const reader = new FileReader()
      reader.readAsText(file)
      reader.onload = function () {
        const xmlStr = this.result
        that.value = xmlStr
        that.createNewDiagram(xmlStr)
      }
    },
    /* ------------------------------------------------ refs methods ------------------------------------------------------ */
    downloadProcessAsXml() {
      this.downloadProcess('xml')
    },
    downloadProcessAsBpmn() {
      this.downloadProcess('bpmn')
    },
    downloadProcessAsSvg() {
      this.downloadProcess('svg')
    },
    processSimulation() {
      this.simulationStatus = !this.simulationStatus
      this.simulation && this.bpmnModeler.get('toggleMode').toggleMode()
    },
    processRedo() {
      this.bpmnModeler.get('commandStack').redo()
    },
    processUndo() {
      this.bpmnModeler.get('commandStack').undo()
    },
    processZoomIn(zoomStep = 0.1) {
      const newZoom = Math.floor(this.defaultZoom * 100 + zoomStep * 100) / 100
      if (newZoom > 4) {
        throw new Error('[Process Designer Warn ]: The zoom ratio cannot be greater than 4')
      }
      this.defaultZoom = newZoom
      this.bpmnModeler.get('canvas').zoom(this.defaultZoom)
    },
    processZoomOut(zoomStep = 0.1) {
      const newZoom = Math.floor(this.defaultZoom * 100 - zoomStep * 100) / 100
      if (newZoom < 0.2) {
        throw new Error('[Process Designer Warn ]: The zoom ratio cannot be less than 0.2')
      }
      this.defaultZoom = newZoom
      this.bpmnModeler.get('canvas').zoom(this.defaultZoom)
    },
    processZoomTo(newZoom = 1) {
      if (newZoom < 0.2) {
        throw new Error('[Process Designer Warn ]: The zoom ratio cannot be less than 0.2')
      }
      if (newZoom > 4) {
        throw new Error('[Process Designer Warn ]: The zoom ratio cannot be greater than 4')
      }
      this.defaultZoom = newZoom
      this.bpmnModeler.get('canvas').zoom(newZoom)
    },
    processReZoom() {
      this.defaultZoom = 1
      this.bpmnModeler.get('canvas').zoom('fit-viewport', 'auto')
    },
    processRestart() {
      this.recoverable = false
      this.revocable = false
      this.createNewDiagram(null).then(() => this.bpmnModeler.get('canvas').zoom(1, 'auto'))
    },
    elementsAlign(align) {
      const Align = this.bpmnModeler.get('alignElements')
      const Selection = this.bpmnModeler.get('selection')
      const SelectedElements = Selection.get()
      if (!SelectedElements || SelectedElements.length <= 1) {
        this.$message.warning('请按住 Ctrl 键选择多个元素对齐')
        return
      }
      this.$confirm('自动对齐可能造成图形变形，是否继续？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => Align.trigger(SelectedElements, align))
    }
    // *-----------------------------    方法结束     ---------------------------------*/
  },
  watch: {
    value(newVal, oldVal) {
      console.log('变更')
      // this.createNewDiagram(newVal)
      // console.log('value', newVal)
    }
  }
}
</script>

<style scoped>
.button-align {
  border: 1px solid transparent;
  border-radius: 5px;
  background-color: #fff;
  font-size: 14px;
  margin-right: 3px;
}
.button-align:hover,
.button-align:focus {
  color: #0d4711;
  background-color: #fff;
  border-color: #0d4711;
}
.button-file {
  margin-left: 5px;
  margin-left: 5px;
}
</style>
