var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "panel-tab__content" },
    [
      _c(
        "div",
        { staticClass: "panel-tab__content--title" },
        [
          _c("span", [_vm._v("消息列表")]),
          _c(
            "a-button",
            {
              attrs: { size: "small", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.openModel("message")
                },
              },
            },
            [_vm._v("创建新消息")]
          ),
        ],
        1
      ),
      _c("a-table", {
        attrs: {
          size: "small",
          columns: _vm.messageListColumns,
          "data-source": _vm.messageList,
          "max-height": "240",
          pagination: false,
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (text, record, index) {
              return _c("span", {}, [_vm._v(" " + _vm._s(index + 1) + " ")])
            },
          },
        ]),
      }),
      _c(
        "div",
        {
          staticClass: "panel-tab__content--title",
          staticStyle: {
            "padding-top": "8px",
            "margin-top": "8px",
            "border-top": "1px solid #eeeeee",
          },
        },
        [
          _c("span", [_vm._v("信号列表")]),
          _c(
            "a-button",
            {
              attrs: { size: "small", type: "primary", icon: "el-icon-plus" },
              on: {
                click: function ($event) {
                  return _vm.openModel("signal")
                },
              },
            },
            [_vm._v("创建新信号")]
          ),
        ],
        1
      ),
      _c("a-table", {
        attrs: {
          size: "small",
          columns: _vm.signalListColumns,
          "data-source": _vm.signalList,
          "max-height": "240",
          pagination: false,
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (text, record, index) {
              return _c("span", {}, [_vm._v(" " + _vm._s(index + 1) + " ")])
            },
          },
        ]),
      }),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.modelConfig.title,
            visible: _vm.modelVisible,
            width: "400px",
          },
          on: {
            ok: _vm.addNewObject,
            cancel: function ($event) {
              _vm.modelVisible = false
            },
          },
        },
        [
          _c(
            "a-form",
            {
              attrs: {
                form: _vm.modelObjectForm,
                "label-col": { span: 5 },
                "wrapper-col": { span: 19 },
              },
            },
            [
              _c(
                "a-form-item",
                { attrs: { label: _vm.modelConfig.idLabel } },
                [
                  _c("a-input", {
                    attrs: { allowClear: "" },
                    model: {
                      value: _vm.modelObjectForm.id,
                      callback: function ($$v) {
                        _vm.$set(_vm.modelObjectForm, "id", $$v)
                      },
                      expression: "modelObjectForm.id",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: _vm.modelConfig.nameLabel } },
                [
                  _c("a-input", {
                    attrs: { allowClear: "" },
                    model: {
                      value: _vm.modelObjectForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.modelObjectForm, "name", $$v)
                      },
                      expression: "modelObjectForm.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }