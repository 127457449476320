var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "margin-top": "16px" } },
    [
      _c(
        "a-form-item",
        { attrs: { label: "审批用户" } },
        [
          _c(
            "a-select",
            {
              attrs: { "show-search": "", filterable: "" },
              on: {
                change: function ($event) {
                  return _vm.updateElementTask("assignee")
                },
              },
              model: {
                value: _vm.userTaskForm.assignee,
                callback: function ($$v) {
                  _vm.$set(_vm.userTaskForm, "assignee", $$v)
                },
                expression: "userTaskForm.assignee",
              },
            },
            _vm._l(_vm.userList, function (ak) {
              return _c(
                "a-select-option",
                { key: ak.userName, attrs: { value: ak.userName } },
                [_vm._v(" " + _vm._s(ak.userName) + " ")]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: "候选用户" } },
        [
          _c(
            "a-select",
            {
              attrs: { mode: "multiple" },
              on: {
                change: function ($event) {
                  return _vm.updateElementTask("candidateUsers")
                },
              },
              model: {
                value: _vm.userTaskForm.candidateUsers,
                callback: function ($$v) {
                  _vm.$set(_vm.userTaskForm, "candidateUsers", $$v)
                },
                expression: "userTaskForm.candidateUsers",
              },
            },
            _vm._l(_vm.userList, function (ak) {
              return _c(
                "a-select-option",
                { key: ak.userName, attrs: { value: ak.userName } },
                [_vm._v(" " + _vm._s(ak.userName) + " ")]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }