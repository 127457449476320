<template>
  <div class="panel-tab__content">
    <!-- <el-form size="mini" label-width="90px" @submit.native.prevent>
      <el-form-item label="ID">
        <el-input
          v-model="elementBaseInfo.id"
          :disabled="idEditDisabled || elementBaseInfo.$type === 'bpmn:Process'"
          clearable
          @change="updateBaseInfo('id')"
        />
      </el-form-item>
      <el-form-item label="名称">
        <el-input v-model="elementBaseInfo.name" clearable @change="updateBaseInfo('name')" />
      </el-form-item>
      <template v-if="elementBaseInfo.$type === 'bpmn:Process'">
        <el-form-item label="版本标签">
          <el-input v-model="elementBaseInfo.versionTag" clearable @change="updateBaseInfo('versionTag')" />
        </el-form-item>
        <el-form-item label="可执行">
          <el-switch
            v-model="elementBaseInfo.isExecutable"
            active-text="是"
            inactive-text="否"
            @change="updateBaseInfo('isExecutable')"
          />
        </el-form-item>
      </template>
    </el-form> -->
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
      <a-form-item label="流程key">
        <a-input
          v-model="elementBaseInfo.id"
          allowClear
          @change="updateBaseInfo('id')"
        />
      </a-form-item>
      <a-form-item label="流程名称">
        <a-input v-model="elementBaseInfo.name" allowClear @change="updateBaseInfo('name')" />
      </a-form-item>
      <!--流程的基础属性-->
      <template v-if="elementBaseInfo.$type === 'bpmn:Process'">
        <a-form-item label="版本标签">
          <a-input v-model="elementBaseInfo.versionTag" allowClear @change="updateBaseInfo('versionTag')" />
        </a-form-item>
        <a-form-item label="可执行">
          <a-switch v-model="elementBaseInfo.isExecutable" @change="updateBaseInfo('isExecutable')" />
        </a-form-item>
      </template>
    </a-form>
  </div>
</template>
<script>
export default {
  name: 'ElementBaseInfo',
  props: {
    businessObject: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: () => {}
    },
    idEditDisabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      elementBaseInfo: {}
    }
  },
  watch: {
    businessObject: {
      immediate: false,
      handler: function(val) {
        if (val) {
          this.$nextTick(() => this.resetBaseInfo())
        }
      }
    }
  },
  methods: {
    resetBaseInfo() {
      this.bpmnElement = window?.bpmnInstances?.bpmnElement
      this.elementBaseInfo = JSON.parse(JSON.stringify(this.bpmnElement.businessObject))
    },
    updateBaseInfo(key) {
      const attrObj = Object.create(null)
      attrObj[key] = this.elementBaseInfo[key]
      if (key === 'id') {
        window.bpmnInstances.modeling.updateProperties(this.bpmnElement, {
          id: this.elementBaseInfo[key],
          di: { id: `${this.elementBaseInfo[key]}_di` }
        })
      } else {
        window.bpmnInstances.modeling.updateProperties(this.bpmnElement, attrObj)
      }
    }
  },
  beforeDestroy() {
    this.bpmnElement = null
  }
}
</script>
<style>
.panel-tab__content .ant-form-item {
  margin-bottom: 0px !important;
}
</style>
