import { render, staticRenderFns } from "./ReceiveTask.vue?vue&type=template&id=792c8dca&"
import script from "./ReceiveTask.vue?vue&type=script&lang=js&"
export * from "./ReceiveTask.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/RevolutionCloud_ra-web-admin_pF1p/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('792c8dca')) {
      api.createRecord('792c8dca', component.options)
    } else {
      api.reload('792c8dca', component.options)
    }
    module.hot.accept("./ReceiveTask.vue?vue&type=template&id=792c8dca&", function () {
      api.rerender('792c8dca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/bpmn/refactor/task/task-components/ReceiveTask.vue"
export default component.exports