var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-process-designer" },
    [
      _c(
        "div",
        { staticClass: "my-process-designer__header" },
        [
          _vm._t("control-header"),
          !_vm.$slots["control-header"]
            ? [
                _c(
                  "a-button-group",
                  [
                    _c(
                      "a-button",
                      {
                        staticClass: "button-file",
                        attrs: {
                          size: _vm.headerButtonSize,
                          type: _vm.headerButtonType,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$refs.refFile.click()
                          },
                        },
                      },
                      [_vm._v("打开文件")]
                    ),
                    _c("a-button", { on: { click: _vm.handleMenuClick } }, [
                      _vm._v(" 下载文件 "),
                    ]),
                    _c(
                      "a-button",
                      {
                        staticClass: "button-file",
                        attrs: {
                          size: _vm.headerButtonSize,
                          type: _vm.headerButtonType,
                        },
                        on: { click: _vm.beforeDoSave },
                      },
                      [_vm._v("保存")]
                    ),
                  ],
                  1
                ),
                _c(
                  "a-button-group",
                  [
                    _c(
                      "a-tooltip",
                      { attrs: { effect: "light", content: "向左对齐" } },
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v(" 向左对齐 "),
                        ]),
                        _c("img", {
                          staticClass: "button-align",
                          attrs: { src: _vm.alignLeft, alt: "" },
                          on: {
                            click: function ($event) {
                              return _vm.elementsAlign("left")
                            },
                          },
                        }),
                      ],
                      2
                    ),
                    _c(
                      "a-tooltip",
                      { attrs: { effect: "light", content: "向右对齐" } },
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v(" 向右对齐 "),
                        ]),
                        _c("img", {
                          staticClass: "button-align",
                          attrs: { src: _vm.alignRight, alt: "" },
                          on: {
                            click: function ($event) {
                              return _vm.elementsAlign("right")
                            },
                          },
                        }),
                      ],
                      2
                    ),
                    _c(
                      "a-tooltip",
                      { attrs: { effect: "light", content: "向上对齐" } },
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v(" 向上对齐 "),
                        ]),
                        _c("img", {
                          staticClass: "button-align",
                          attrs: { src: _vm.alignTop, alt: "" },
                          on: {
                            click: function ($event) {
                              return _vm.elementsAlign("top")
                            },
                          },
                        }),
                      ],
                      2
                    ),
                    _c(
                      "a-tooltip",
                      { attrs: { effect: "light", content: "向下对齐" } },
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v(" 向下对齐 "),
                        ]),
                        _c("img", {
                          staticClass: "button-align",
                          attrs: { src: _vm.alignBottom, alt: "" },
                          on: {
                            click: function ($event) {
                              return _vm.elementsAlign("bottom")
                            },
                          },
                        }),
                      ],
                      2
                    ),
                    _c(
                      "a-tooltip",
                      { attrs: { effect: "light", content: "水平居中" } },
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v(" 水平居中 "),
                        ]),
                        _c("img", {
                          staticClass: "button-align",
                          attrs: { src: _vm.alignCenter, alt: "" },
                          on: {
                            click: function ($event) {
                              return _vm.elementsAlign("center")
                            },
                          },
                        }),
                      ],
                      2
                    ),
                    _c(
                      "a-tooltip",
                      { attrs: { effect: "light", content: "垂直居中" } },
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v(" 垂直居中 "),
                        ]),
                        _c("img", {
                          staticClass: "button-align",
                          attrs: { src: _vm.alignMiddle, alt: "" },
                          on: {
                            click: function ($event) {
                              return _vm.elementsAlign("middle")
                            },
                          },
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "a-button-group",
                  [
                    _c(
                      "a-tooltip",
                      { attrs: { effect: "light", content: "缩小视图" } },
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v(" 缩小视图 "),
                        ]),
                        _c("img", {
                          staticClass: "button-align",
                          attrs: {
                            src: _vm.zoomOut,
                            alt: "",
                            disabled: _vm.defaultZoom < 0.2,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.processZoomOut()
                            },
                          },
                        }),
                      ],
                      2
                    ),
                    _c("a-button", { attrs: { size: _vm.headerButtonSize } }, [
                      _vm._v(
                        _vm._s(Math.floor(this.defaultZoom * 10 * 10) + "%")
                      ),
                    ]),
                    _c(
                      "a-tooltip",
                      { attrs: { effect: "light", content: "放大视图" } },
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v(" 放大视图 "),
                        ]),
                        _c("img", {
                          staticClass: "button-align",
                          attrs: {
                            src: _vm.zoomIn,
                            alt: "",
                            disabled: _vm.defaultZoom > 4,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.processZoomIn()
                            },
                          },
                        }),
                      ],
                      2
                    ),
                    _c(
                      "a-tooltip",
                      { attrs: { effect: "light", content: "重置视图并居中" } },
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v(" 重置视图并居中 "),
                        ]),
                        _c("img", {
                          staticClass: "button-align",
                          attrs: { src: _vm.alignOriginal, alt: "" },
                          on: {
                            click: function ($event) {
                              return _vm.processReZoom()
                            },
                          },
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "a-button-group",
                  [
                    _c(
                      "a-tooltip",
                      { attrs: { effect: "light", content: "撤销" } },
                      [
                        _c("template", { slot: "title" }, [_vm._v(" 撤销 ")]),
                        _c("img", {
                          staticClass: "button-align",
                          attrs: {
                            src: _vm.undoImg,
                            alt: "",
                            disabled: !_vm.revocable,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.processUndo()
                            },
                          },
                        }),
                      ],
                      2
                    ),
                    _c(
                      "a-tooltip",
                      { attrs: { effect: "light", content: "恢复" } },
                      [
                        _c("template", { slot: "title" }, [_vm._v(" 恢复 ")]),
                        _c("img", {
                          staticClass: "button-align",
                          attrs: {
                            src: _vm.redoImg,
                            alt: "",
                            disabled: !_vm.revocable,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.processRedo()
                            },
                          },
                        }),
                      ],
                      2
                    ),
                    _c(
                      "a-tooltip",
                      { attrs: { effect: "light", content: "重新绘制" } },
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v(" 重新绘制 "),
                        ]),
                        _c("img", {
                          staticClass: "button-align",
                          attrs: { src: _vm.restartImg, alt: "" },
                          on: {
                            click: function ($event) {
                              return _vm.processRestart()
                            },
                          },
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c("input", {
            ref: "refFile",
            staticStyle: { display: "none" },
            attrs: { type: "file", id: "files", accept: ".bpmn" },
            on: { change: _vm.importLocalFile },
          }),
        ],
        2
      ),
      _c("div", { staticClass: "my-process-designer__container" }, [
        _c("div", {
          ref: "bpmn-canvas",
          staticClass: "my-process-designer__canvas",
        }),
      ]),
      _c(
        "a-modal",
        {
          attrs: {
            title: "保存",
            visible: _vm.setDeploymentNameVisible,
            "z-index": 10000,
          },
          on: {
            ok: _vm.handleSetDeploymentNameOk,
            cancel: function ($event) {
              _vm.setDeploymentNameVisible = false
            },
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "流程部署名称" } },
            [
              _c("a-input", {
                model: {
                  value: _vm.deploymentName,
                  callback: function ($$v) {
                    _vm.deploymentName = $$v
                  },
                  expression: "deploymentName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "所属机构" } },
            [
              _c("a-tree-select", {
                staticStyle: { width: "100%" },
                attrs: {
                  "dropdown-style": { maxHeight: "400px", overflow: "auto" },
                  "tree-data": _vm.deptOptions,
                  placeholder: "请选择",
                  replaceFields: _vm.replaceFields,
                  "tree-default-expand-all": "",
                },
                model: {
                  value: _vm.deptId,
                  callback: function ($$v) {
                    _vm.deptId = $$v
                  },
                  expression: "deptId",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }