var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "panel-tab__content" },
    [
      _c(
        "a-form",
        {
          attrs: {
            form: _vm.form,
            "label-col": { span: 5 },
            "wrapper-col": { span: 19 },
          },
        },
        [
          _c(
            "a-form-item",
            { attrs: { label: "流程key" } },
            [
              _c("a-input", {
                attrs: { allowClear: "" },
                on: {
                  change: function ($event) {
                    return _vm.updateBaseInfo("id")
                  },
                },
                model: {
                  value: _vm.elementBaseInfo.id,
                  callback: function ($$v) {
                    _vm.$set(_vm.elementBaseInfo, "id", $$v)
                  },
                  expression: "elementBaseInfo.id",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "流程名称" } },
            [
              _c("a-input", {
                attrs: { allowClear: "" },
                on: {
                  change: function ($event) {
                    return _vm.updateBaseInfo("name")
                  },
                },
                model: {
                  value: _vm.elementBaseInfo.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.elementBaseInfo, "name", $$v)
                  },
                  expression: "elementBaseInfo.name",
                },
              }),
            ],
            1
          ),
          _vm.elementBaseInfo.$type === "bpmn:Process"
            ? [
                _c(
                  "a-form-item",
                  { attrs: { label: "版本标签" } },
                  [
                    _c("a-input", {
                      attrs: { allowClear: "" },
                      on: {
                        change: function ($event) {
                          return _vm.updateBaseInfo("versionTag")
                        },
                      },
                      model: {
                        value: _vm.elementBaseInfo.versionTag,
                        callback: function ($$v) {
                          _vm.$set(_vm.elementBaseInfo, "versionTag", $$v)
                        },
                        expression: "elementBaseInfo.versionTag",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  { attrs: { label: "可执行" } },
                  [
                    _c("a-switch", {
                      on: {
                        change: function ($event) {
                          return _vm.updateBaseInfo("isExecutable")
                        },
                      },
                      model: {
                        value: _vm.elementBaseInfo.isExecutable,
                        callback: function ($$v) {
                          _vm.$set(_vm.elementBaseInfo, "isExecutable", $$v)
                        },
                        expression: "elementBaseInfo.isExecutable",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }