var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "panel-tab__content" },
    [
      _c(
        "a-form",
        {
          attrs: {
            form: _vm.flowConditionForm,
            "label-col": { span: 5 },
            "wrapper-col": { span: 19 },
          },
        },
        [
          _c(
            "a-form-item",
            { attrs: { label: "流转类型" } },
            [
              _c(
                "a-select",
                {
                  on: { change: _vm.updateFlowType },
                  model: {
                    value: _vm.flowConditionForm.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.flowConditionForm, "type", $$v)
                    },
                    expression: "flowConditionForm.type",
                  },
                },
                [
                  _c("a-select-option", { attrs: { value: "normal" } }, [
                    _vm._v(" 普通流转路径 "),
                  ]),
                  _c("a-select-option", { attrs: { value: "default" } }, [
                    _vm._v(" 默认流转路径 "),
                  ]),
                  _c("a-select-option", { attrs: { value: "condition" } }, [
                    _vm._v(" 条件流转路径 "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.flowConditionForm.type === "condition"
            ? _c(
                "a-form-item",
                { key: "condition", attrs: { label: "条件格式" } },
                [
                  _c(
                    "a-select",
                    {
                      model: {
                        value: _vm.flowConditionForm.conditionType,
                        callback: function ($$v) {
                          _vm.$set(_vm.flowConditionForm, "conditionType", $$v)
                        },
                        expression: "flowConditionForm.conditionType",
                      },
                    },
                    [
                      _c(
                        "a-select-option",
                        { attrs: { value: "expression" } },
                        [_vm._v(" 表达式 ")]
                      ),
                      _c("a-select-option", { attrs: { value: "script" } }, [
                        _vm._v(" 脚本 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.flowConditionForm.conditionType &&
          _vm.flowConditionForm.conditionType === "expression"
            ? _c(
                "a-form-item",
                { key: "express", attrs: { label: "表达式" } },
                [
                  _c("a-input", {
                    attrs: { allowClear: "" },
                    on: { change: _vm.updateFlowCondition },
                    model: {
                      value: _vm.flowConditionForm.body,
                      callback: function ($$v) {
                        _vm.$set(_vm.flowConditionForm, "body", $$v)
                      },
                      expression: "flowConditionForm.body",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.flowConditionForm.conditionType &&
          _vm.flowConditionForm.conditionType === "script"
            ? [
                _c(
                  "a-form-item",
                  { key: "language", attrs: { label: "脚本语言" } },
                  [
                    _c("a-input", {
                      attrs: { allowClear: "" },
                      on: { change: _vm.updateFlowCondition },
                      model: {
                        value: _vm.flowConditionForm.language,
                        callback: function ($$v) {
                          _vm.$set(_vm.flowConditionForm, "language", $$v)
                        },
                        expression: "flowConditionForm.language",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  { key: "scriptType", attrs: { label: "脚本类型" } },
                  [
                    _c(
                      "a-select",
                      {
                        model: {
                          value: _vm.flowConditionForm.scriptType,
                          callback: function ($$v) {
                            _vm.$set(_vm.flowConditionForm, "scriptType", $$v)
                          },
                          expression: "flowConditionForm.scriptType",
                        },
                      },
                      [
                        _c(
                          "a-select-option",
                          { attrs: { value: "inlineScript" } },
                          [_vm._v(" 内联脚本 ")]
                        ),
                        _c(
                          "a-select-option",
                          { attrs: { value: "externalScript" } },
                          [_vm._v(" 外部脚本 ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.flowConditionForm.scriptType === "inlineScript"
                  ? _c(
                      "a-form-item",
                      { key: "body", attrs: { label: "脚本" } },
                      [
                        _c("a-input", {
                          attrs: { type: "textarea", allowClear: "" },
                          on: { change: _vm.updateFlowCondition },
                          model: {
                            value: _vm.flowConditionForm.body,
                            callback: function ($$v) {
                              _vm.$set(_vm.flowConditionForm, "body", $$v)
                            },
                            expression: "flowConditionForm.body",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.flowConditionForm.scriptType === "externalScript"
                  ? _c(
                      "a-form-item",
                      { key: "resource", attrs: { label: "资源地址" } },
                      [
                        _c("a-input", {
                          attrs: { allowClear: "" },
                          on: { change: _vm.updateFlowCondition },
                          model: {
                            value: _vm.flowConditionForm.resource,
                            callback: function ($$v) {
                              _vm.$set(_vm.flowConditionForm, "resource", $$v)
                            },
                            expression: "flowConditionForm.resource",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }