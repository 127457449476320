var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "panel-tab__content" },
    [
      _c(
        "a-form",
        { attrs: { "label-col": { span: 5 }, "wrapper-col": { span: 19 } } },
        [
          _c(
            "a-form-item",
            { attrs: { label: "表单标识" } },
            [
              _c("a-input", {
                attrs: { allowClear: "" },
                on: { change: _vm.updateElementFormKey },
                model: {
                  value: _vm.formKey,
                  callback: function ($$v) {
                    _vm.formKey = $$v
                  },
                  expression: "formKey",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "业务标识" } },
            [
              _c(
                "a-select",
                {
                  on: { change: _vm.updateElementBusinessKey },
                  model: {
                    value: _vm.businessKey,
                    callback: function ($$v) {
                      _vm.businessKey = $$v
                    },
                    expression: "businessKey",
                  },
                },
                [
                  _vm._l(_vm.fieldList, function (i) {
                    return _c(
                      "a-select-option",
                      { key: i.id, attrs: { value: i.id } },
                      [_vm._v(" " + _vm._s(i.label) + " ")]
                    )
                  }),
                  _c("a-select-option", { attrs: { value: "" } }, [
                    _vm._v(" 无 "),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "element-property list-property" },
        [
          _c("a-divider", [_vm._v("表单字段")]),
          _c("a-table", {
            attrs: {
              size: "small",
              columns: _vm.columns,
              "data-source": _vm.fieldList,
              "max-height": "240",
              pagination: false,
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function (text, record, index) {
                  return _c("span", {}, [_vm._v(" " + _vm._s(index + 1) + " ")])
                },
              },
              {
                key: "type",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.fieldType[record.type] || record.type) +
                        " "
                    ),
                  ])
                },
              },
              {
                key: "action",
                fn: function (text, record, index) {
                  return _c(
                    "span",
                    {},
                    [
                      _c("a-divider", { attrs: { type: "vertical" } }),
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.openFieldForm(record, index)
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      ),
                      _c("a-divider", { attrs: { type: "vertical" } }),
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.removeField(record, index)
                            },
                          },
                        },
                        [_vm._v(" 移除 ")]
                      ),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "element-drawer__button" },
        [
          _c(
            "a-button",
            {
              attrs: { type: "primary", icon: "plus" },
              on: {
                click: function ($event) {
                  return _vm.openFieldForm(null, -1)
                },
              },
            },
            [_vm._v("添加字段")]
          ),
        ],
        1
      ),
      _c(
        "a-drawer",
        {
          attrs: {
            title: "字段配置",
            placement: "right",
            closable: true,
            visible: _vm.fieldModelVisible,
            width: _vm.width + "px",
            wrapClassName: "field-model-drawer",
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "a-form",
            {
              attrs: {
                form: _vm.formFieldForm,
                "label-col": { span: 5 },
                "wrapper-col": { span: 19 },
              },
            },
            [
              _c(
                "a-form-item",
                { attrs: { label: "字段ID" } },
                [
                  _c("a-input", {
                    attrs: { allowClear: "" },
                    model: {
                      value: _vm.formFieldForm.id,
                      callback: function ($$v) {
                        _vm.$set(_vm.formFieldForm, "id", $$v)
                      },
                      expression: "formFieldForm.id",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "类型" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "请选择字段类型", allowClear: "" },
                      on: { change: _vm.changeFieldTypeType },
                      model: {
                        value: _vm.formFieldForm.typeType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formFieldForm, "typeType", $$v)
                        },
                        expression: "formFieldForm.typeType",
                      },
                    },
                    _vm._l(_vm.fieldType, function (value, key) {
                      return _c(
                        "a-select-option",
                        { key: key, attrs: { value: key } },
                        [_vm._v(" " + _vm._s(value) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.formFieldForm.typeType === "custom"
                ? _c(
                    "a-form-item",
                    { attrs: { label: "类型名称" } },
                    [
                      _c("a-input", {
                        attrs: { allowClear: "" },
                        model: {
                          value: _vm.formFieldForm.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.formFieldForm, "type", $$v)
                          },
                          expression: "formFieldForm.type",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-item",
                { attrs: { label: "名称" } },
                [
                  _c("a-input", {
                    attrs: { allowClear: "" },
                    model: {
                      value: _vm.formFieldForm.label,
                      callback: function ($$v) {
                        _vm.$set(_vm.formFieldForm, "label", $$v)
                      },
                      expression: "formFieldForm.label",
                    },
                  }),
                ],
                1
              ),
              _vm.formFieldForm.typeType === "date"
                ? _c(
                    "a-form-item",
                    { attrs: { label: "时间格式" } },
                    [
                      _c("a-input", {
                        attrs: { allowClear: "" },
                        model: {
                          value: _vm.formFieldForm.datePattern,
                          callback: function ($$v) {
                            _vm.$set(_vm.formFieldForm, "datePattern", $$v)
                          },
                          expression: "formFieldForm.datePattern",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-item",
                { attrs: { label: "默认值" } },
                [
                  _c("a-input", {
                    attrs: { allowClear: "" },
                    model: {
                      value: _vm.formFieldForm.defaultValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.formFieldForm, "defaultValue", $$v)
                      },
                      expression: "formFieldForm.defaultValue",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.formFieldForm.type === "enum"
            ? [
                _c("a-divider", { key: "enum-divider" }),
                _c(
                  "p",
                  { key: "enum-title", staticClass: "listener-filed__title" },
                  [
                    _c("span", [_vm._v("枚举值列表：")]),
                    _c(
                      "a-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.openFieldOptionForm(null, -1, "enum")
                          },
                        },
                      },
                      [_vm._v("添加枚举值")]
                    ),
                  ],
                  1
                ),
                _c("a-table", {
                  attrs: {
                    size: "small",
                    columns: _vm.fieldEnumListColumns,
                    "data-source": _vm.fieldEnumList,
                    "max-height": "240",
                    pagination: false,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "index",
                        fn: function (text, record, index) {
                          return _c("span", {}, [
                            _vm._v(" " + _vm._s(index + 1) + " "),
                          ])
                        },
                      },
                      {
                        key: "action",
                        fn: function (text, record, index) {
                          return _c(
                            "span",
                            {},
                            [
                              _c("a-divider", { attrs: { type: "vertical" } }),
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.openFieldOptionForm(
                                        record,
                                        index,
                                        "enum"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _c("a-divider", { attrs: { type: "vertical" } }),
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeFieldOptionItem(
                                        record,
                                        index,
                                        "enum"
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" 移除 ")]
                              ),
                            ],
                            1
                          )
                        },
                      },
                    ],
                    null,
                    false,
                    3652084912
                  ),
                }),
              ]
            : _vm._e(),
          _c("a-divider", { key: "validation-divider" }),
          _c(
            "p",
            { key: "validation-title", staticClass: "listener-filed__title" },
            [
              _c("span", [_vm._v("约束条件列表：")]),
              _c(
                "a-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.openFieldOptionForm(null, -1, "constraint")
                    },
                  },
                },
                [_vm._v("添加约束")]
              ),
            ],
            1
          ),
          _c("a-table", {
            key: "validation-table",
            staticStyle: { "min-height": "200px" },
            attrs: {
              size: "small",
              columns: _vm.fieldConstraintsListColumns,
              "data-source": _vm.fieldConstraintsList,
              pagination: false,
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function (text, record, index) {
                  return _c("span", {}, [_vm._v(" " + _vm._s(index + 1) + " ")])
                },
              },
              {
                key: "action",
                fn: function (text, record, index) {
                  return _c(
                    "span",
                    {},
                    [
                      _c("a-divider", { attrs: { type: "vertical" } }),
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.openFieldOptionForm(
                                record,
                                index,
                                "constraint"
                              )
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      ),
                      _c("a-divider", { attrs: { type: "vertical" } }),
                      _c(
                        "a",
                        {
                          staticStyle: { color: "#ff4d4f" },
                          on: {
                            click: function ($event) {
                              return _vm.removeFieldOptionItem(
                                record,
                                index,
                                "constraint"
                              )
                            },
                          },
                        },
                        [_vm._v(" 移除 ")]
                      ),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("el-divider", { key: "property-divider" }),
          _c(
            "p",
            { key: "property-title", staticClass: "listener-filed__title" },
            [
              _c("span", [_vm._v("字段属性列表：")]),
              _c(
                "a-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.openFieldOptionForm(null, -1, "property")
                    },
                  },
                },
                [_vm._v("添加属性")]
              ),
            ],
            1
          ),
          _c("a-table", {
            key: "property-table",
            staticStyle: { "min-height": "200px" },
            attrs: {
              size: "small",
              columns: _vm.fieldPropertiesListColumns,
              "data-source": _vm.fieldPropertiesList,
              pagination: false,
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function (text, record, index) {
                  return _c("span", {}, [_vm._v(" " + _vm._s(index + 1) + " ")])
                },
              },
              {
                key: "action",
                fn: function (text, record, index) {
                  return _c(
                    "span",
                    {},
                    [
                      _c("a-divider", { attrs: { type: "vertical" } }),
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.openFieldOptionForm(
                                record,
                                index,
                                "property"
                              )
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      ),
                      _c("a-divider", { attrs: { type: "vertical" } }),
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.removeFieldOptionItem(
                                record,
                                index,
                                "property"
                              )
                            },
                          },
                        },
                        [_vm._v(" 移除 ")]
                      ),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c(
            "div",
            { staticClass: "element-drawer__button" },
            [
              _c("a-button", { attrs: { size: "small" } }, [_vm._v("取 消")]),
              _c(
                "a-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.saveField },
                },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.optionModelTitle,
            visible: _vm.fieldOptionModelVisible,
            width: "600px",
          },
          on: {
            ok: _vm.saveFieldOption,
            cancel: function ($event) {
              _vm.fieldOptionModelVisible = false
            },
          },
        },
        [
          _c(
            "a-form",
            {
              attrs: {
                form: _vm.formFieldForm,
                "label-col": { span: 5 },
                "wrapper-col": { span: 19 },
              },
            },
            [
              _vm.fieldOptionType !== "constraint"
                ? _c(
                    "a-form-item",
                    { key: "option-id", attrs: { label: "编号/ID" } },
                    [
                      _c("a-input", {
                        model: {
                          value: _vm.fieldOptionForm.id,
                          callback: function ($$v) {
                            _vm.$set(_vm.fieldOptionForm, "id", $$v)
                          },
                          expression: "fieldOptionForm.id",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.fieldOptionType !== "property"
                ? _c(
                    "a-form-item",
                    { key: "option-name", attrs: { label: "名称" } },
                    [
                      _c("a-input", {
                        attrs: { allowClear: "" },
                        model: {
                          value: _vm.fieldOptionForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.fieldOptionForm, "name", $$v)
                          },
                          expression: "fieldOptionForm.name",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.fieldOptionType === "constraint"
                ? _c(
                    "a-form-item",
                    { key: "option-config", attrs: { label: "配置" } },
                    [
                      _c("a-input", {
                        attrs: { allowClear: "" },
                        model: {
                          value: _vm.fieldOptionForm.config,
                          callback: function ($$v) {
                            _vm.$set(_vm.fieldOptionForm, "config", $$v)
                          },
                          expression: "fieldOptionForm.config",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.fieldOptionType === "property"
                ? _c(
                    "a-form-item",
                    { key: "option-value", attrs: { label: "值" } },
                    [
                      _c("a-input", {
                        attrs: { allowClear: "" },
                        model: {
                          value: _vm.fieldOptionForm.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.fieldOptionForm, "value", $$v)
                          },
                          expression: "fieldOptionForm.value",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }