<template>
  <vxe-modal
    v-model="open"
    width="100%"
    height="100%"
    :title="formTitle"
    :show-zoom="true"
    resize
    :lock-view="false"
    :mask="true"
    @close="modalClose"
  >
    <div id="myapp">
      <my-process-designer
        :key="`designer-${reloadIndex}`"
        v-model="xmlString"
        v-bind="controlForm"
        keyboard
        ref="processDesigner"
        @element-click="elementClick"
        @init-finished="initModeler"
      />
      <my-properties-panel
        :key="`penal-${reloadIndex}`"
        :bpmn-modeler="modeler"
        :prefix="controlForm.prefix"
        class="process-panel"
      />

      <!-- demo config -->
      <!-- <div class="demo-control-bar">
        <div class="open-control-dialog" @click="controlDrawerVisible = true"><a-icon type="appstore" /></div>
      </div> -->

      <a-drawer
        title="偏好设置"
        placement="right"
        :closable="true"
        :visible="controlDrawerVisible"
        @close="onClose"
        width="400px"
      >
        <a-form :form="controlForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
          <a-form-item label="流程ID">
            <a-input v-model="controlForm.processId" @change="reloadProcessDesigner" />
          </a-form-item>
          <a-form-item label="流程名称">
            <a-input v-model="controlForm.processName" @change="reloadProcessDesigner" />
          </a-form-item>

          <a-form-item label="流程引擎">
            <a-radio-group v-model="controlForm.prefix" @change="reloadProcessDesigner">
              <a-radio :style="radioStyle" value="camunda">camunda</a-radio>
              <a-radio :style="radioStyle" value="flowable">flowable</a-radio>
              <a-radio :style="radioStyle" value="activiti">activiti</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-form>
      </a-drawer>
    </div>
  </vxe-modal>
</template>

<script>
import { getDefinitionXML } from '@/api/approval/processDefinition'
// 自定义元素选中时的弹出菜单（修改 默认任务 为 用户任务）
import CustomContentPadProvider from '@/components/bpmn/process-designer/plugins/content-pad'
// 自定义左侧菜单（修改 默认任务 为 用户任务）
import CustomPaletteProvider from '@/components/bpmn/process-designer/plugins/palette'
import Vue from 'vue'
import MyProcessDesigner from '@/components/bpmn/index.js'
Vue.use(MyProcessDesigner)

export default {
  name: 'ProcessDesigner',
  components: {},
  data() {
    return {
      xmlString: '',
      modeler: null,
      reloadIndex: 0,
      controlDrawerVisible: false,
      // translationsSelf: translations,
      controlForm: {
        processId: '',
        processName: '',
        simulation: true,
        labelEditing: false,
        labelVisible: false,
        prefix: 'activiti',
        headerButtonSize: 'mini',
        // additionalModel: []
        additionalModel: [CustomContentPadProvider, CustomPaletteProvider]
      },
      addis: {},
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px'
      },
      formTitle: '流程设计',
      open: false
    }
  },
  created() {
    // console.log(this.translationsSelf);
  },
  methods: {
    handleAdd() {
      this.xmlString = ''
      this.open = true
      this.$nextTick(() => {
        this.$refs.processDesigner.createNewDiagram('')
      })
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      const query = {
        deploymentId: row.deploymentId,
        resourceName: row.resourceName
      }
      getDefinitionXML(query).then(response => {
        this.xmlString = response
        this.$nextTick(() => {
          this.$refs.processDesigner.createNewDiagram(this.xmlString)
        })
        this.open = true
      })
    },
    modalClose() {
      this.open = false
      this.$emit('ok')
    },
    onClose() {
      this.controlDrawerVisible = false
    },
    initModeler(modeler) {
      setTimeout(() => {
        this.modeler = modeler
        console.log(modeler)
      }, 10)
    },
    reloadProcessDesigner() {
      this.controlForm.additionalModel = []
      for (const key in this.addis) {
        if (this.addis[key]) {
          this.controlForm.additionalModel.push(this.addis[key])
        }
      }
      this.reloadIndex += 1
      this.modeler = null // 避免 panel 异常
    },
    changeLabelEditingStatus(status) {
      this.addis.labelEditing = status ? { labelEditingProvider: ['value', ''] } : false
      this.reloadProcessDesigner()
    },
    changeLabelVisibleStatus(status) {
      //   this.addis.customRenderer = status ? CustomRenderer : false
      //   this.reloadProcessDesigner()
    },
    elementClick(element) {
      this.element = element
      // console.log(xmlObj2json(this.xmlString));
      console.log(this.modeler)
      if (element.type === 'bpmn:UserTask') {
        const moddle = window.bpmnInstances.moddle
        const modeling = window.bpmnInstances.modeling
        const child1 = moddle.create('flowable:ChildField', {
          id: 'child1',
          name: '1',
          readable: true
        })
        const child2 = moddle.create('flowable:ChildField', {
          id: 'child2',
          name: '2',
          type: 'string',
          required: true
        })
        const formProperty = moddle.create('flowable:FormProperty', {
          children: [child1, child2]
          // children: []
        })
        const extensionElements = moddle.create('bpmn:ExtensionElements', {
          values: [formProperty]
        })
        modeling.updateProperties(element, {
          extensionElements
        })
      }
    }
  }
}
</script>

<style lang="less">
#myapp {
  width: 100%;
  height: calc(100vh - 50px);
  box-sizing: border-box;
  display: inline-grid;
  grid-template-columns: auto max-content;
}
.demo-control-bar {
  position: fixed;
  right: 8px;
  bottom: 8px;
  z-index: 1;
  .open-control-dialog {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 32px;
    background: rgba(64, 158, 255, 1);
    color: #ffffff;
    cursor: pointer;
  }
}
.control-form {
  .el-radio {
    width: 100%;
    line-height: 32px;
  }
}
body,
body * {
  /* 滚动条 */
  &::-webkit-scrollbar-track-piece {
    background-color: #fff; /*滚动条的背景颜色*/
    -webkit-border-radius: 0; /*滚动条的圆角宽度*/
  }
  &::-webkit-scrollbar {
    width: 10px; /*滚动条的宽度*/
    height: 8px; /*滚动条的高度*/
  }
  &::-webkit-scrollbar-thumb:vertical {
    /*垂直滚动条的样式*/
    height: 50px;
    background-color: rgba(153, 153, 153, 0.5);
    -webkit-border-radius: 4px;
    outline: 2px solid #fff;
    outline-offset: -2px;
    border: 2px solid #fff;
  }
  &::-webkit-scrollbar-thumb {
    /*滚动条的hover样式*/
    background-color: rgba(159, 159, 159, 0.3);
    -webkit-border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    /*滚动条的hover样式*/
    background-color: rgba(159, 159, 159, 0.5);
    -webkit-border-radius: 4px;
  }
  .bjs-powered-by {
    width: 0;
  }
}
</style>
