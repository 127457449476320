var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vxe-modal",
    {
      attrs: {
        width: "100%",
        height: "100%",
        title: _vm.formTitle,
        "show-zoom": true,
        resize: "",
        "lock-view": false,
        mask: true,
      },
      on: { close: _vm.modalClose },
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _c(
        "div",
        { attrs: { id: "myapp" } },
        [
          _c(
            "my-process-designer",
            _vm._b(
              {
                key: "designer-" + _vm.reloadIndex,
                ref: "processDesigner",
                attrs: { keyboard: "" },
                on: {
                  "element-click": _vm.elementClick,
                  "init-finished": _vm.initModeler,
                },
                model: {
                  value: _vm.xmlString,
                  callback: function ($$v) {
                    _vm.xmlString = $$v
                  },
                  expression: "xmlString",
                },
              },
              "my-process-designer",
              _vm.controlForm,
              false
            )
          ),
          _c("my-properties-panel", {
            key: "penal-" + _vm.reloadIndex,
            staticClass: "process-panel",
            attrs: {
              "bpmn-modeler": _vm.modeler,
              prefix: _vm.controlForm.prefix,
            },
          }),
          _c(
            "a-drawer",
            {
              attrs: {
                title: "偏好设置",
                placement: "right",
                closable: true,
                visible: _vm.controlDrawerVisible,
                width: "400px",
              },
              on: { close: _vm.onClose },
            },
            [
              _c(
                "a-form",
                {
                  attrs: {
                    form: _vm.controlForm,
                    "label-col": { span: 5 },
                    "wrapper-col": { span: 19 },
                  },
                },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "流程ID" } },
                    [
                      _c("a-input", {
                        on: { change: _vm.reloadProcessDesigner },
                        model: {
                          value: _vm.controlForm.processId,
                          callback: function ($$v) {
                            _vm.$set(_vm.controlForm, "processId", $$v)
                          },
                          expression: "controlForm.processId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "流程名称" } },
                    [
                      _c("a-input", {
                        on: { change: _vm.reloadProcessDesigner },
                        model: {
                          value: _vm.controlForm.processName,
                          callback: function ($$v) {
                            _vm.$set(_vm.controlForm, "processName", $$v)
                          },
                          expression: "controlForm.processName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "流程引擎" } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          on: { change: _vm.reloadProcessDesigner },
                          model: {
                            value: _vm.controlForm.prefix,
                            callback: function ($$v) {
                              _vm.$set(_vm.controlForm, "prefix", $$v)
                            },
                            expression: "controlForm.prefix",
                          },
                        },
                        [
                          _c(
                            "a-radio",
                            {
                              style: _vm.radioStyle,
                              attrs: { value: "camunda" },
                            },
                            [_vm._v("camunda")]
                          ),
                          _c(
                            "a-radio",
                            {
                              style: _vm.radioStyle,
                              attrs: { value: "flowable" },
                            },
                            [_vm._v("flowable")]
                          ),
                          _c(
                            "a-radio",
                            {
                              style: _vm.radioStyle,
                              attrs: { value: "activiti" },
                            },
                            [_vm._v("activiti")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }