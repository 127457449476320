<template>
  <div style="margin-top: 16px">
    <a-form-item label="审批用户">
      <a-select show-search v-model="userTaskForm.assignee" filterable @change="updateElementTask('assignee')">
        <a-select-option v-for="ak in userList" :key="ak.userName" :value="ak.userName">
          {{ ak.userName }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item label="候选用户">
      <a-select
        v-model="userTaskForm.candidateUsers"
        mode="multiple"
        @change="updateElementTask('candidateUsers')"
      >
        <a-select-option v-for="ak in userList" :key="ak.userName" :value="ak.userName">
          {{ ak.userName }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <!-- <a-form-item label="候选分组">
      <a-select
        v-model="userTaskForm.candidateGroups"
        multiple
        collapse-tags
        @change="updateElementTask('candidateGroups')"
      >
        <a-select-option v-for="gk in mockData" :key="'ass-' + gk" :value="`group${gk}`">
          {{ `分组${gk}` }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item label="到期时间">
      <a-input v-model="userTaskForm.dueDate" allowClear @change="updateElementTask('dueDate')" />
    </a-form-item>
    <a-form-item label="跟踪时间">
      <a-input v-model="userTaskForm.followUpDate" allowClear @change="updateElementTask('followUpDate')" />
    </a-form-item>
    <a-form-item label="优先级">
      <a-input v-model="userTaskForm.priority" allowClear @change="updateElementTask('priority')" />
    </a-form-item> -->
  </div>
</template>

<script>
import { listUser } from '@/api/system/user'
export default {
  name: 'UserTask',
  props: {
    id: {
      type: String,
      default: () => {}
    },
    type: {
      type: String,
      default: () => {}
    }
  },
  data() {
    return {
      defaultTaskForm: {
        assignee: '',
        candidateUsers: [],
        candidateGroups: [],
        dueDate: '',
        followUpDate: '',
        priority: ''
      },
      userTaskForm: {},
      mockData: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      queryParam: {},
      userList: []
    }
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        this.bpmnElement = window.bpmnInstances.bpmnElement
        this.$nextTick(() => this.resetTaskForm())
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    /** 查询用户列表 */
    getList() {
      listUser(this.queryParam).then(response => {
        this.userList = response.rows
      })
    },
    resetTaskForm() {
      for (const key in this.defaultTaskForm) {
        let value
        if (key === 'candidateUsers' || key === 'candidateGroups') {
          value = this.bpmnElement?.businessObject[key] ? this.bpmnElement.businessObject[key].split(',') : []
        } else {
          value = this.bpmnElement?.businessObject[key] || this.defaultTaskForm[key]
        }
        this.$set(this.userTaskForm, key, value)
      }
    },
    updateElementTask(key) {
      const taskAttr = Object.create(null)
      if (key === 'candidateUsers' || key === 'candidateGroups') {
        taskAttr[key] = this.userTaskForm[key] && this.userTaskForm[key].length ? this.userTaskForm[key].join() : null
      } else {
        taskAttr[key] = this.userTaskForm[key] || null
      }
      window.bpmnInstances.modeling.updateProperties(this.bpmnElement, taskAttr)
    }
  },
  beforeDestroy() {
    this.bpmnElement = null
  }
}
</script>
