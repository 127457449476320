var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "panel-tab__content" },
    [
      _c(
        "a-form",
        {
          attrs: {
            form: _vm.loopInstanceForm,
            "label-col": { span: 5 },
            "wrapper-col": { span: 19 },
          },
        },
        [
          _c(
            "a-form-item",
            { attrs: { label: "回路特性" } },
            [
              _c(
                "a-select",
                {
                  on: { change: _vm.changeLoopCharacteristicsType },
                  model: {
                    value: _vm.loopCharacteristics,
                    callback: function ($$v) {
                      _vm.loopCharacteristics = $$v
                    },
                    expression: "loopCharacteristics",
                  },
                },
                [
                  _c(
                    "a-select-option",
                    { attrs: { value: "ParallelMultiInstance" } },
                    [_vm._v(" 并行多重事件 ")]
                  ),
                  _c(
                    "a-select-option",
                    { attrs: { value: "SequentialMultiInstance" } },
                    [_vm._v(" 时序多重事件 ")]
                  ),
                  _c("a-select-option", { attrs: { value: "StandardLoop" } }, [
                    _vm._v(" 循环事件 "),
                  ]),
                  _c("a-select-option", { attrs: { value: "Null" } }, [
                    _vm._v(" 无 "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.loopCharacteristics === "ParallelMultiInstance" ||
          _vm.loopCharacteristics === "SequentialMultiInstance"
            ? [
                _c(
                  "a-form-item",
                  { key: "loopCardinality", attrs: { label: "循环基数" } },
                  [
                    _c("a-input", {
                      attrs: { allowClear: "" },
                      on: { change: _vm.updateLoopCardinality },
                      model: {
                        value: _vm.loopInstanceForm.loopCardinality,
                        callback: function ($$v) {
                          _vm.$set(_vm.loopInstanceForm, "loopCardinality", $$v)
                        },
                        expression: "loopInstanceForm.loopCardinality",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  { key: "collection", attrs: { label: "集合" } },
                  [
                    _c("a-input", {
                      attrs: { allowClear: "" },
                      on: { change: _vm.updateLoopBase },
                      model: {
                        value: _vm.loopInstanceForm.collection,
                        callback: function ($$v) {
                          _vm.$set(_vm.loopInstanceForm, "collection", $$v)
                        },
                        expression: "loopInstanceForm.collection",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  { key: "elementVariable", attrs: { label: "元素变量" } },
                  [
                    _c("a-input", {
                      attrs: { allowClear: "" },
                      on: { change: _vm.updateLoopBase },
                      model: {
                        value: _vm.loopInstanceForm.elementVariable,
                        callback: function ($$v) {
                          _vm.$set(_vm.loopInstanceForm, "elementVariable", $$v)
                        },
                        expression: "loopInstanceForm.elementVariable",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  { key: "completionCondition", attrs: { label: "完成条件" } },
                  [
                    _c("a-input", {
                      attrs: { allowClear: "" },
                      on: { change: _vm.updateLoopCondition },
                      model: {
                        value: _vm.loopInstanceForm.completionCondition,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.loopInstanceForm,
                            "completionCondition",
                            $$v
                          )
                        },
                        expression: "loopInstanceForm.completionCondition",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  { key: "async", attrs: { label: "异步状态" } },
                  [
                    _c(
                      "a-checkbox",
                      {
                        on: {
                          change: function ($event) {
                            return _vm.updateLoopAsync("asyncBefore")
                          },
                        },
                        model: {
                          value: _vm.loopInstanceForm.asyncBefore,
                          callback: function ($$v) {
                            _vm.$set(_vm.loopInstanceForm, "asyncBefore", $$v)
                          },
                          expression: "loopInstanceForm.asyncBefore",
                        },
                      },
                      [_vm._v(" 异步前 ")]
                    ),
                    _c(
                      "a-checkbox",
                      {
                        on: {
                          change: function ($event) {
                            return _vm.updateLoopAsync("asyncAfter")
                          },
                        },
                        model: {
                          value: _vm.loopInstanceForm.asyncAfter,
                          callback: function ($$v) {
                            _vm.$set(_vm.loopInstanceForm, "asyncAfter", $$v)
                          },
                          expression: "loopInstanceForm.asyncAfter",
                        },
                      },
                      [_vm._v(" 异步后 ")]
                    ),
                    _vm.loopInstanceForm.asyncAfter ||
                    _vm.loopInstanceForm.asyncBefore
                      ? _c(
                          "a-checkbox",
                          {
                            on: {
                              change: function ($event) {
                                return _vm.updateLoopAsync("exclusive")
                              },
                            },
                            model: {
                              value: _vm.loopInstanceForm.exclusive,
                              callback: function ($$v) {
                                _vm.$set(_vm.loopInstanceForm, "exclusive", $$v)
                              },
                              expression: "loopInstanceForm.exclusive",
                            },
                          },
                          [_vm._v(" 排除 ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.loopInstanceForm.asyncAfter ||
                _vm.loopInstanceForm.asyncBefore
                  ? _c(
                      "a-form-item",
                      {
                        key: "timeCycle",
                        attrs: { label: "重试周期", prop: "timeCycle" },
                      },
                      [
                        _c("a-input", {
                          attrs: { clearable: "" },
                          on: { change: _vm.updateLoopTimeCycle },
                          model: {
                            value: _vm.loopInstanceForm.timeCycle,
                            callback: function ($$v) {
                              _vm.$set(_vm.loopInstanceForm, "timeCycle", $$v)
                            },
                            expression: "loopInstanceForm.timeCycle",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }